import styled from 'styled-components';
import { size, colors } from '../../config';

export const Projects = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 80px 0 80px;
	h2 {
		font-family: 'Inter';
		font-weight: 200;
	}
	h4 {
		font-family: 'helios', Helvetica, Arial, sans-serif;
		color: ${colors.GREY_TEXT};
		font-size: 1.2rem
	}
	@media (min-width: ${size.MOBILEXL}px) {
		h4 {
			font-size: 1.8rem
		}
	}
	@media (min-width: ${size.TABLET}px) {
		padding: 100px 0 80px;
		h4 {
			/* font-size: 2.4rem */
		}
	}
`;
export const Loader = styled.div`
	width: 100%;
	height: 80vh;
	display: flex;
	justify-content: center;
	align-items: center;
	img {
		width: 80px;
	}
`;
