import React from "react";
import { RenderIcon } from "../../utils";
import { LogoCard } from "./styles";

const LogoCardComponent = props => {
  return (
    <LogoCard size={props.size} style={props.style}>
      {RenderIcon(props.logo)}
    </LogoCard>
  );
};

LogoCardComponent.defaultProps = {
  size: 50,
  logo: ""
};

export default LogoCardComponent;
