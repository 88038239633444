import styled from "styled-components";
import { animated } from "react-spring";
import { size, colors } from "../../config";

export const Value = styled.div`
	height: 45px;
	/* overflow: hidden; */
	margin: 0 0 ${size.SMALL}px 0;
	margin-left: 0px;

	@media (min-width: ${size.DESKTOP_SMALL}px) {
		height: 70px;
		margin-top: ${size.TINY}px;
		margin-left: ${size.SMALL}px;
	}
`;
export const ValueItem = styled(animated.div)`
    height: 45px;
    color: ${({ dark }) =>
			dark === "true" ? colors.ORANGE_1 : colors.ORANGE_3};

    @media (min-width: ${size.TABLET}px) {
      margin: ${4}px 8px;
    }

    @media (min-width: ${size.DESKTOP_SMALL}px) {
      margin: 0px;
    }

    .react-rotating-text-cursor {
      animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s infinite;
      
      @media (min-width: ${size.DESKTOP_SMALL}px) {
        height: 70px;
      }
    }

    @keyframes blinking-cursor {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
`;
