import styled from 'styled-components';
import { size } from '../../config';

export const Content = styled.div``;

export const Inner = styled.div`
	width: calc(100% - ${size.SMALL}px);
	height: calc(100vh - ${size.SMALL}px);
	margin: 8px;

	@media (min-width: ${size.TABLET}px) {
		margin: ${size.SMALL}px;
		width: calc(100% - ${size.MEDIUM}px);
		height: calc(100vh - ${size.MEDIUM}px);
	}
`;
