import styled from "styled-components";
import { size, colors } from "../../config";

export const Bio = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: ${size.PADDING_SMALL}px ${size.PADDING_SMALL}px;
	font-size: 1.5rem;
	margin: 320px 0 ${size.LARGE}px;
	p {
		color: ${colors.GREY_TEXT};
	}
	h4 {
		font-size: 18px;
	}
	a:link,
	a:active,
	a:visited {
		background: ${colors.GRAD_ORANGE};
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;

		text-decoration: none;
		padding-bottom: 0px;
		border-bottom: 1px solid ${colors.ORANGE_1};;
	}
	a:hover {
		background: ${colors.ORANGE_2};
		border-bottom: 1px dashed #c3c3c3;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	.bio-tech {
		font-family: 'Inter';
		font-weight: 200;
		font-size: 1.5rem;
	}

	button {
		margin: ${size.MEDIUM}px 0;
		font-size: 1.2rem;
		span {
			font-size: 1.2rem;
		}
	}

	@media (min-width: ${size.TABLET}px) {
		margin: 120px 0 0;
		padding: ${size.PADDING_SMALL}px ${size.PADDING_LARGE}px;

		.bio-tech {
			font-size: 2.5rem;
		}
	}

	@media (min-width: ${size.DESKTOP}px) {
		margin: 50px 0 0;
	}
`;

export const Title = styled.h1`
	font-size: 2.4rem;
	line-height: 2.4rem;
	letter-spacing: 0.1rem;
	font-family: 'helios';
	color: ${colors.ORANGE_2};
	padding-bottom: 0;
	margin-bottom: ${size.MEDIUM}px;

	@media (min-width: ${size.MOBILEXL}px) {
		font-size: 3rem;
		line-height: 3rem;
		margin-top: 20vh;
	}
	@media (min-width: ${size.DESKTOP_SMALL}px) {
		font-size: 4rem;
		line-height: 4rem;

		.bottom-row {
			margin-top: ${size.MEDIUM}px;
		}
	}
`;
export const Info = styled.div`
	font-size: 1.5rem;
	line-height: 1.7rem;
	padding-bottom: ${size.MEDIUM}px;
	color: ${colors.ORANGE_1};
	/* max-width: 600px; */
`;
