import React, {useState, useEffect, useRef} from "react";
import Preloader from "../preloader";

import { Image, ImgContainer, Loader } from "./styles";

const ImageLoadComponent = (props) => {

	const [imgError, setImgError] = useState(false);
	const [imgLoaded, setImgLoaded] = useState(false);
	const imgRef = useRef(null);


	const onError = (err) => {
		setImgError(true)
		console.log("error loading image", err)
	};

	const onLoad = (err) => {
		setImgLoaded(true)
		// console.log("img loaded", imgError, imgLoaded)
	};
	
	return (
		<>
		<ImgContainer className="imgContainer">
            {!imgError && <Image 
				ref={imgRef}
                className="lozad"
				src={`${props.src}`} 
				alt={props.alt} 
				style={{display: !imgLoaded ? 'none' : 'block'}} 
				onError={(err) => onError(err)}
				onLoad={onLoad}
			/>}
			{!imgLoaded && !imgError && <Loader className="imgLoader"><Preloader /></Loader>}
			{imgError && <Loader className="imgLoader"><Preloader /></Loader>}
		</ImgContainer>
		</>
	);
};

export default ImageLoadComponent;
