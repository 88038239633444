import React, {useEffect} from "react";
import {  ImgContainer, Bar } from "./styles";

const PreloaderComponent = (props) => {
    const [reload, setReload] = React.useState(false)
    
    useEffect(() => {
        setReload(true);
    }, []);
	return (
		<>
		<ImgContainer>
            <Bar ><div className='bar1'/></Bar>
            <Bar ><div className='bar2'/></Bar>
            <Bar ><div className='bar3'/></Bar>
		</ImgContainer>
		</>
	);
};

export default PreloaderComponent;
