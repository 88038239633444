import React, { useContext } from 'react';
import AppContext from '../../context';
import { withRouter } from 'react-router-dom';
import { Button, Ball, ServiceCard } from '..';

import { Bio, BioRow, Tech } from './styles';

const ServiceComponent = props => {
	const state = useContext(AppContext);

	return (
		<Bio>
			<div className='container'>

				<BioRow>
					<div className='bio-tech'>
						<h4 className='color-highlight_dark'>
							Ways of working:
						</h4>

						<div className='card-container'>
						<ServiceCard
							title='Bespoke Build'
							description='Embark on the journey of transforming your idea, MVP, or service into reality with a custom-tailored approach. This service offers the design and development of your digital product, meticulously crafted to meet your specific needs.'
							link="Get in touch to kickstart your project"
							mailMsg="Project enquiry..."
						/>

						<ServiceCard
							title='Contractor for Hire'
							description="In need of a React Native expert to integrate seamlessly within your team? Consider hiring Josh Freeman as a consultant for your project. With his expertise, he can significantly contribute to shaping your team's dynamics and project trajectory."
							link="Get in touch to discuss availability and rates"
							mailMsg="Contractor availability enquiry..."
						/>

						</div>
					</div>
				</BioRow>
			</div>
		</Bio>
	);
};

export default withRouter(ServiceComponent);
