import styled from "styled-components";
import { colors } from "../../config";

export const Image = styled.img`
	width: 100%;
	height: auto;
	background: ${colors.GREY_DARK};
`;

export const ImgContainer = styled.div`
	background: ${colors.GREY_DARK};
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
`;

export const Loader = styled.div`
	width: 50px;
	padding: 23% 0;
`;
