import styled from "styled-components";
import { size } from "../../config";
export const LogoCard = styled.div`
	border-radius: 10px;
	background: white;
	/* box-shadow: 0px 2px 2px rgba(23, 23, 57, 0.2); */
	/* box-shadow: -8px -4px 8px 0px #e2e1e1, 8px 4px 12px 0px #e2d1e6,
		4px 4px 4px 0px #ebf0f7 inset, -4px -4px 4px 0px #f3f9f5 inset; */
	padding: 20px;
	width: 100%;
	height: 60px;
	display: flex;
	margin: ${size.TINY}px;
	justify-content: center;
	align-items: center;

	svg {
		height: ${({ size }) => size}px;
		fill: rgba(0, 0, 0, 0.5);
		transition: fill 1s ease;
	}

	&:hover {
		svg {
			fill: rgba(206, 76, 255, 1);
		}
	}

	@media (min-width: ${size.MOBILEXL}px) {
		padding: 20px;
		width: 130px;
		height: 70px;
		margin: ${size.SMALL}px;
	}
`;
