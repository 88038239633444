import React, { useContext } from 'react';
import AppContext from '../../context';
import { withRouter } from 'react-router-dom';
import { RichText } from 'prismic-reactjs';
import { linkResolver } from '../../utils/tools';
import { Button, Ball } from '../../components';

import { Bio, BioRow, Tech } from './styles';

const BioComponent = props => {
	const state = useContext(AppContext);

	return (
		<Bio>
			<div className='container'>
				<BioRow>
					<div>

						<div className="avatar">
							<img src={'/images/avatar.jpg'} alt='Josh Freeman' />
						</div>
						{/* <Ball offsetX={55} offsetY={50} />
						<Ball offsetX={200} offsetY={80} className="ball2" /> */}
					</div>
					<div
						className='text-size-medium'
						style={{
							transform: `translateY(-${props.offset * 0.01}px)`
						}}>
						<div className='text-size-medium bioDesc'>
							{state.home &&
								state.home['bio-body'] &&
								state.home['bio-body'][0] &&
								RichText.render(state.home['bio-body'], linkResolver)}
						</div>
		
					</div>
				</BioRow>

				<BioRow>
					<div
						className='text-size-medium'
						style={{
							transform: `translateY(-${props.offset * 0.01}px)`
						}}>		
						<Tech>
							<div className='bio-tech'>
								<h4 className='color-highlight'>
									Working with technologies:
								</h4>
								{state.home &&
									state.home.techlist &&
									state.home.techlist.map((item, i) => {
										const tag = item['techlist-item'][0].text;
										return (
											<span key={tag}>
												{tag}
												{i < state.home.techlist.length - 1 && (
													<span className='color-highlight'> - </span>
												)}
											</span>
										);
									})}
							</div>
						</Tech>
					</div>
				</BioRow>

				<BioRow>
					<div className='bio-tech'>
						<h4 className='color-highlight_dark'>
							building solutions:
						</h4>
						{state.home &&
							state.home.servicelist &&
							state.home.servicelist.map((item, i) => {
								const tag = item['servicelist-item'][0].text;
								return (
									<span key={tag}>
										{tag}
										{i < state.home.servicelist.length - 1 && (
											<span className='color-highlight_dark'> - </span>
										)}
									</span>
								);
							})}
						<div>
							<Button
								onPress={() => props.history.push('./mvp-development')}
								text='Have an idea?'
								// icon="question"
							/>
						</div>
					</div>
					<div className='desk-stuff'>
						<img
							src={'/images/elements_protos.png'}
							alt='Have an idea?'
						/>
					</div>
				</BioRow>
			</div>
		</Bio>
	);
};

export default withRouter(BioComponent);
