import styled from 'styled-components';
import { colors, size } from '../../config';

export const ProjThumb = styled.div`
	width: 100%;
	margin: 10px 0 10px;
	position: relative;
	display: flex;
	flex-direction: column;

	h3 {
		margin: 0;
		font-size: 1rem;
		line-height: 1.2rem;
		letter-spacing: 0.1rem;
		padding: 4px 14px;
		display: inline-flex;
		color: ${colors.ORANGE_3};
		transform: translate(0, 0px);
		transition: transform .3s ease, opacity .5s ease;
		font-family: 'helios';
	}

	h2 {
		font-size: 1.5rem;
		line-height: 1.8rem;
		letter-spacing: 0.1rem;
		margin: 0px 0;
		padding: 0px 14px 6px;
		display: inline;
		color: ${colors.GREY_TEXT};
		transform: translate(0, 0px);
		transition: transform .8s ease, opacity .5s ease;
		/* font-family: 'helios'; */
	}

	img {
		transition: transform .4s ease;
		cursor: pointer;
	}

	.border-highlight {
		border: 8px solid transparent;
		transition: border .2s ease, transform .4s ease;
	}

	@media (min-width: ${size.MOBILEXL}px) {
		width: 85vw;
	}

	@media (min-width: ${size.TABLET}px) {
		/* flex-direction: row; */
		width: 75vw;
		max-width: 1400px;
		margin: 10px 0 ${size.LARGE}px;

		&:hover {
			cursor: pointer;

			img {
				/* transform: scale(1.02) translateX(-0px) translateY(-0px); */
			}
			.border-highlight {
				border: 8px solid ${colors.ORANGE_2};
				transform: scale(1.02) translateX(-0px) translateY(-0px);
			}
			h2 {
				opacity: 1;
				transform: translate(0, 10px);
			}
			h3 {
				opacity: 1;
				color: ${colors.ORANGE_1};
				transform: translate(0, 15px);
			}
		}
	}

	@media (min-width: ${size.TABLET}px) {
	
	}
`;

export const Loader = styled.img`width: 150px;`;

export const ImgContainer = styled.div`
	width: auto;
	box-shadow: 0px 2px 10px rgba(23, 23, 57, 0.12);
	overflow: hidden;
	flex: 1;
	border-radius: ${size.MEDIUM}px;
	overflow: hidden;

	&:hover {
		box-shadow: 0px 2px 5px rgba(23, 33, 77, 0.2);
	}

	@media (min-width: ${size.TABLET}px) {
		border-radius: ${size.LARGE}px;
		width: 100%;
		flex: 4;
	}
`;

export const Titles = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	margin: ${size.SMALL}px 0;

	@media (min-width: ${size.TABLET}px) {
		margin: ${size.SMALL}px;
	}
`;
