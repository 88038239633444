import React from "react";
import { Card } from "./styles.js";

const CardComponent = props => {
    const { title, description, link, className, mailMsg } = props;
	return <Card style={{ 
        }} 
        className={className}>
            <div className="card-inner">
                <div className='text-size-medium card-title'>{title}</div>
                <div className='text-size-medium'>{description}</div>
                <div className='text-size-medium card-link'>
                    <a href={`mailto:info@deprogram.io?subject=${mailMsg}`}>{link}</a>
                </div>
            </div>
        </Card>;
};

CardComponent.defaultProps = {
	title: "",
	description: "",
    link: "",
    className: "",
    mailMsg: "Let's work together!"
};

export default CardComponent;
