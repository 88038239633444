import React from "react";
import { Ball } from "./styles.js";

const BallComponent = props => {
    const { offsetX, offsetY, className } = props;
	return <Ball style={{ 
            top: offsetY, 
            left: offsetX
        }} 
        className={className} />;
};

BallComponent.defaultProps = {
	offsetX: 0,
	offsetY: 0,
    className: ""
};

export default BallComponent;
