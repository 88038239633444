import styled from "styled-components";
import { size } from "../../config";

export const Clients = styled.div`
	z-index: 2;
	/* height: 100vh; */
	min-height: 800px;
	position: relative;
	padding: ${size.PADDING_SMALL}px ${size.PADDING_SMALL}px;

	@media (min-width: ${size.TABLET}px) {
		margin: 10vh 0 0px;
		padding: ${size.PADDING_SMALL}px ${size.PADDING_LARGE}px;
	}
`;
export const Title = styled.h2`
	font-size: 1.5rem;
	line-height: 1.5rem;
	letter-spacing: 0.1rem;
	/* margin-top: -250px; */
	font-family: 'Inter';
	font-weight: 200;
	@media (min-width: ${size.TABLET}px) {
		font-size: 2.5rem;
		line-height: 2rem;
		/* margin-top: -250px; */
	}
`;

export const Content = styled.div`
	margin: 0 0 ${size.PADDING_MEDIUM}px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	max-width: 900px;
`;
