import React, { useEffect } from 'react';
import lozad from 'lozad';
import VanillaTilt from 'vanilla-tilt';
import { withRouter } from 'react-router-dom';
import MVPBottom from '../../assets/images/mvp.png';

import {
	Cover,
	Title,
	Info,
	Row,
	RowLabel,
	ImgBtm,
	Img,
	PhoneContainer,
	Making,
	Arrow
} from './styles';
import { Image } from '..';

const CoverComponent = React.memo(props => {
	const isIOS =
		/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

	useEffect(() => {
		// Setup lazy loading
		const observer = lozad();
		observer.observe();

		VanillaTilt.init(document.querySelectorAll('.tilt'), {
			max: 10,
			speed: 800,
			reverse: true,
			perspective: 1000, // Transform perspective, the lower the more extreme the tilt gets.
			scale: 1, // 2 = 200%, 1.5 = 150%, etc..
			transition: true,
			axis: null,
			reset: true,
			easing: 'cubic-bezier(.03,.98,.52,.99)',
			'full-page-listening': true
		});

		VanillaTilt.init(document.querySelector('.tilt-bg'), {
			max: 12,
			speed: 900,
			// reverse: true,
			perspective: 800,
			scale: 1, // 2 = 200%, 1.5 = 150%, etc..
			transition: true,
			axis: null,
			reset: true,
			easing: 'cubic-bezier(.03,.98,.52,.99)',
			'full-page-listening': true
		});

		return () => {};
	}, []);

	return (
		<Cover className='vs-section'>
			<div className='container'>
				<Title>
					Have an idea for a digital service or product?<br />
				</Title>
				<h3 className='color-highlight_dark'>
					Let's start with the{' '}
					<span className='color-highlight'>Minimum Viable Product</span>
				</h3>
				<div>
					<Info>
					Embarking on this journey with the development of a Minimum Viable Product (MVP) is a strategic first step. At Deprogram, expertise and experience are key in guiding clients through the optimal approach to bringing their visions to life. With a multitude of tools available, the focus is on identifying key features, testing them with users, and launching the right product in the market with a modern and scalable tech stack.
					</Info>
				</div>
				<PhoneContainer>
					<ImgBtm className='tilt-bg'>
						<Image src={MVPBottom} />
					</ImgBtm>
				</PhoneContainer>
				<PhoneContainer>
					<Img className='tilt'>
						{/* <img src={MVPTop} /> */}
					</Img>

					<Making className='handwriting tiltText'>
						<Arrow >
						<svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 139.76 116.5"><defs></defs>
							<path className="path" d="M4.21,115.38c3.83-5.49,6.93-11.42,9.35-17.66l-2.77,1.57c11.77,4.06,24.09,6.38,36.53,6.94,1.32.06,2.11-1.09,2.25-2.25,1.19-9.94,1.65-19.95,1.38-29.95l-2.85,2.17c12.42,4.03,24.44,9.15,35.95,15.31,1.47.78,3.42-.18,3.39-1.94-.28-14.56-.56-29.12-.84-43.68l-4.42.6c2.01,5.06,6.89,7.3,11.77,8.89,6.08,1.99,12.28,3.6,18.56,4.86,1.13.23,2.51-.31,2.77-1.57,3.75-18.59,8.55-36.97,14.38-55.02.89-2.76-3.45-3.95-4.34-1.2-5.83,18.05-10.63,36.42-14.38,55.02l2.77-1.57c-5.66-1.13-11.25-2.55-16.75-4.28-3.56-1.12-8.89-2.44-10.44-6.33-.41-1.04-1.28-1.79-2.47-1.61-1.08.16-1.97,1.07-1.95,2.21.28,14.56.56,29.12.84,43.68l3.39-1.94c-11.85-6.34-24.25-11.61-37.03-15.76-1.37-.45-2.89.75-2.85,2.17.27,10-.19,20.01-1.38,29.95l2.25-2.25c-12.05-.54-23.93-2.85-35.33-6.78-1.23-.43-2.35.5-2.77,1.57-2.27,5.87-5.29,11.43-8.9,16.59-.7,1-.18,2.5.81,3.08,1.12.66,2.38.19,3.08-.81h0Z"/>
							<path className="path" d="M126.23,3.06c.4,7.59,6.62,12.83,9.08,19.6.42,1.14,1.53,1.91,2.77,1.57,1.1-.3,1.99-1.62,1.57-2.77-1.26-3.47-3.31-6.5-5.25-9.62-1.68-2.7-3.51-5.75-3.67-8.79-.06-1.21-.99-2.25-2.25-2.25-1.17,0-2.31,1.03-2.25,2.25h0Z"/>
							<path className="path" d="M126.19.31c-6.14,3.45-12.45,6.61-18.89,9.46-1.11.49-1.37,2.11-.81,3.08.67,1.15,1.96,1.3,3.08.81,6.44-2.85,12.75-6.01,18.89-9.46,2.53-1.42.26-5.31-2.27-3.89h0Z"/>
						</svg>
						</Arrow>
						<div className='tilt'>
							An MVP has just enough <br />
							core features to effectively<br />
							deploy the product, and no more.
						</div>
					</Making>
				</PhoneContainer>
			</div>
		</Cover>
	);
});

export default withRouter(CoverComponent);
