import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

import { Error, Footer, Header } from "../../components";
import { Inner } from "../../assets/styles/root";
import "../../assets/styles/base.css";

const Home = props => {

	return (
		<div className="App color-bg">
			<Inner>
				<Header />
				<div className="vs-section">
					<Error />
					<Footer />
				</div>
			</Inner>
		</div>
	);
};

export default withRouter(Home);
