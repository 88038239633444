import styled from "styled-components";
import { size } from "../../config";
export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: linear-gradient(
    10deg,
    rgba(206, 76, 255, 1) 10%,
    rgba(24, 14, 246, 1) 100%
  ); */
  z-index: 99;
  pointer-events: none;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity .4s ease;
`;
export const Inner = styled.div`
  background: rgba(255, 255, 255, 0.4);
  width: calc(100% - ${size.SMALL}px);
  height: calc(100% - ${size.SMALL}px);

  @media (min-width: ${size.TABLET}px) {
    width: calc(100% - ${size.MEDIUM}px);
    height: calc(100% - ${size.MEDIUM}px);
  }
`;
