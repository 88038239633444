import styled from "styled-components";
import { colors, size } from "../../config";

export const Button = styled.button`
	width: 100%;
	border-radius: 12px;
	border: 0;
	height: 50px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	color: ${colors.SLATE};
	font-size: 1.2em;
	line-height: 1.2em;
	padding-top: 4px;
	cursor: pointer;
	outline: none;
	background: ${colors.GRAD_ORANGE};
	box-shadow: 0px 0px 0px rgba(23, 23, 57, 0);
	font-family: 'Inter', sans-serif;
	font-weight: 600;

	transition: background 0.25s ease, transform .15s ease,
		box-shadow .15s ease;

	svg {
		height: 15px;
		width: 15px;
		fill: ${colors.SLATE};
		transform: translateX(10px);
	}
	&:hover {
		/* background: red; */
		transform: translateY(-3px);
		box-shadow: 0px 8px 2px rgba(23, 23, 57, 0.22);
	}

	@media (min-width: ${size.MOBILEXL}px) {
		max-width: 300px;
	}
`;
