import React from 'react';
import { useAppState } from '../../hooks';
import { withRouter } from 'react-router-dom';
import { ReactComponent as Chevron } from '../../assets/images/chevron.svg';
import { ReactComponent as NavIco } from '../../assets/images/E.svg';

import logo from "../../assets/images/deprogram-24.svg";
import { SET_NAV } from '../../actions';
import { Header } from './styles';

const HeaderComponent = props => {
	const { state, dispatch } = useAppState();

	const onNavPress = () => {
		if (props.backEnabled) props.history.goBack();
		else dispatch({ type: SET_NAV, navigationOpen: !state.navigationOpen });
	};

	const onHomePress = () => {
		props.history.push('/');
	};

	return (
		<Header>
			<div className='header-nav' onClick={onNavPress}>
				{props.backEnabled ? (
					<Chevron className='back-ico' />
				) : (
					<NavIco className='burger' />
				)}
			</div>
			<div className='header-logo' onClick={onHomePress}>
				<img src={logo} alt='deprogram' />
			</div>
		</Header>
	);
};

export default withRouter(HeaderComponent);
