import styled from "styled-components";
import { colors, size } from "../../config";

export const Card = styled.div`
	/* width: 32px;
	height: 32px; */
	border-radius: 24px;
	border: 0;
	outline: none;
	/* background: ${colors.ORANGE_2}; */
    border: 1px solid ${colors.ORANGE_2}ae;
    background-color: ${colors.SLATE}ae;
    box-shadow: 0px 8px 2px rgba(23, 23, 57, 0.1);

    padding: ${size.SMALL}px;
    margin-bottom: 16px;

    color: ${colors.GREY_TEXT};

	transition: transform .15s ease .05s, border .15s ease .05s, box-shadow .15s ease .05s;
    animation: end 2s ease-in-out .2s infinite forwards;

	&:hover {
        border: 1px solid ${colors.ORANGE_3}ae;
		/* box-shadow: 0px 2px 0px rgba(23, 23, 57, 0.22); */
	}

    .card-title {
        color: ${colors.ORANGE_1};
        font-weight: 600;
        margin-bottom: ${size.TINY}px;
    }


    .card-link {
        margin-top: ${size.SMALL}px;
        color: ${colors.ORANGE_3};
        /* font-weight: 400; */
    }

    .card-inner {
        padding: ${size.SMALL}px;
        background-color: ${colors.SLATE}ae;
        border-radius: 24px;
    }
    
    @keyframes end {
        /* 0% { transform: translateY(0%); }
        50% { transform: translateY(40px); } */
    }

    @media (min-width: ${size.DESKTOP}px) {
        margin-bottom: 16px;
        &:nth-child(odd) {
            margin-right: ${size.SMALL / 2}px;
        }
        &:nth-child(even) {
            margin-left: ${size.SMALL / 2}px;
        }
    }

`;