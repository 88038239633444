import React from "react";
import "./assets/styles/index.css";
import App from "./app";
// import * as serviceWorker from "./serviceWorker";

import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App tab="home" />);

// serviceWorker.register(); /* TODO: CACHING STUCK ON OLD VERSION */