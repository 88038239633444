import styled from 'styled-components';
import { size, colors } from '../../config';

export const Cover = styled.div`
	margin: 0;
	height: 80vh;
	min-height: 1000px;
	z-index: 1;
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	padding: ${size.PADDING_SMALL}px ${size.PADDING_SMALL}px;

	.link {
		background: ${colors.GRAD_ORANGE};
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;

		border-bottom: 1px solid ${colors.ORANGE_1};
		cursor: pointer;

		&:hover {
			background: ${colors.ORANGE_2};
			-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
			border-bottom: 1px dashed #c3c3c3;
		}
	}

	@media (min-width: ${size.TABLET}px) {
		min-height: 800px;
		padding: ${size.PADDING_SMALL}px ${size.PADDING_LARGE}px;
	}
`;
export const Title = styled.h1`
	font-size: 2.4rem;
	line-height: 2.4rem;
	letter-spacing: 0.1rem;
	margin-top: 10vh;
	font-family: 'Inter', Helvetica, Arial, sans-serif;
	font-weight: 600;
	color: ${colors.ORANGE_2};
	

	@media (min-width: ${size.MOBILEXL}px) {
		font-size: 3rem;
		line-height: 3rem;
		margin-top: 20vh;
	}
	@media (min-width: ${size.DESKTOP_SMALL}px) {
		font-size: 4.45rem;
		line-height: 4.5rem;

		.bottom-row {
			margin-top: -${size.SMALL}px;
		}
	}
	@media (min-width: ${2000}px) {
		font-size: 6rem;
		line-height: 6rem;

		.bottom-row {
			margin-top: ${size.SMALL}px;
		}
	}
	@media (min-width: ${2500}px) {
		font-size: 8rem;
		line-height: 8rem;

		.bottom-row {
			margin-top: ${size.LARGE}px;
		}
	}
`;
export const Info = styled.p`
	font-size: 1.5rem;
	font-weight: 200;
	padding-bottom: ${size.MEDIUM}px;
	color: ${colors.GREY_TEXT};
`;

export const Row = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	/* background: red; */
	@media (min-width: ${size.TABLET}px) {
		width: calc(100vw - 138px);
		flex-direction: row;
		align-items: center;
	}
`;
export const RowLabel = styled.div`
	height: 50px;
	@media (min-width: ${size.DESKTOP_SMALL}px) {
		height: 75px;
		margin: 0;
	}
`;

export const PhoneContainer = styled.div`
	position: relative;
	transform: translate(0px, 64px) scale(0.8) rotate(-5deg);
	transition: transform .25s ease;
	transform-style: flat;
	/* background-color: red; */


	@media (min-width: ${420}px) {
		transform: translate(0px, 64px) scale(1) rotate(-5deg);
	}

	@media (min-width: ${size.TABLET}px) {
		transform: translate(-0px, -30px) scale(1) rotate(-5deg);
		pointer-events: none;
	}

	@media (min-width: ${size.DESKTOP}px) and (min-height: ${400}px) {
		margin: 0;
		position: absolute;
		right: 0px;
		top: 0px;
		transform: translate(80px, -40px) scale(0.55) rotate(6deg);
	}

	@media (min-width: ${size.DESKTOP}px) and (min-height: ${800}px)  {
		margin: 0;
		position: absolute;
		right: 0%;
		top: 0vh;
		transform: translate(130px, 20px) scale(0.75) rotate(6deg);
	}

	@media (min-width: ${size.DESKTOP_LARGE}px) {
		right: 0;
		transform: translate(0px, -30px) scale(0.7) rotate(0deg);
	}

	@media (min-width: ${1600}px) {
		transform: translate(20px, 0px) scale(0.8) rotate(2deg);
	}

	@media (min-width: ${1700}px) {
		transform: translate(-110px, 40px) scale(0.9) rotate(3deg);
	}

	@media (min-width: ${1300}px) and (min-height: ${1100}px)  {
		margin: 0;
		position: absolute;
		right: 10%;
		top: 2vh;
		transform: translate(250px, 120px) scale(.9) rotate(6deg);
	}

	@media (min-width: ${1500}px) and (min-height: ${1100}px)  {
		margin: 0;
		position: absolute;
		right: 10%;
		top: 2vh;
		transform: translate(130px, 120px) scale(.9) rotate(6deg);
	}

	@media (min-width: ${2000}px) and (min-height: ${1100}px)  {
		transform: translate(130px, 25%) scale(1.1) rotate(6deg);
	}
`;
export const Phone = styled.div`
	position: absolute;
	right: 20px;
	width: 280px;
	height: 610px;
	padding: 16px;
	z-index: 1;
	background-color: ${colors.ORANGE_2}ae;
	border-radius: 24px;
	box-shadow: 0px 4px 20px rgba(23, 23, 57, 0.2);
	overflow: hidden;
	transform-style: flat;
	/* background-color: blue; */
	pointer-events: none;

	video {
		width: 100%;
		border-radius: 24px;
		pointer-events: none;
	}

	@media (min-width: ${size.DESKTOP}px) {
		position: relative;
		width: 450px;
		height: 980px;
	}
`;
export const PhoneBg = styled.div`
	position: absolute;
	right: 0;
	width: 280px;
	padding: 4px;
	margin: 20px -60px 0 0;
	z-index: -1;
	transform-style: flat;

	img {
		width: 100%;
	}

	@media (min-width: ${size.DESKTOP}px) {
		margin: 90px -40px 0 0;
		width: 700px;
	}
`;
export const Making = styled.div`
	position: absolute;
	z-index: 0;
	top: 1115px;
	right: 160px;
	font-size: 32px;
	line-height: 34px;

	transform: translate(60px, -390px) scale(1) rotate(0deg);
	transition: transform .25s ease;
	color: ${colors.ORANGE_1};

	@media (min-width: ${size.MOBILEXL}px) {
		top: 700px;
		right: 390px;
		font-size: 48px;
		line-height: 46px;
		transform: translate(-30px, -300px) scale(1) rotate(0deg);
	}

	@media (min-width: ${size.DESKTOP}px) {
		position: relative;
		top: auto;
		right: auto;
		transform: translate(-400px, -220px) scale(1) rotate(-10deg);
	}

	@media (min-width: ${size.DESKTOP_LARGE}px) {
		transform: translate(-420px, -280px) scale(1) rotate(0deg);
	}

	svg {
		width: 140px;
		display: block;
		/* position: absolute; */
		
		.path {
			fill: ${colors.ORANGE_4};
			stroke-dasharray: 160;
			stroke-dashoffset: 0;
			animation: dash 1s linear;
		}
		
		@keyframes dash {
			from {
				stroke-dashoffset: 160;
			}
			to {
				stroke-dashoffset: 0;
			}
		}
	}
`;

export const Arrow = styled.div`
	position: absolute;
	width: 60px;
	height: auto;
	margin-top: -120px;
	margin-left: 30px;
	transform: rotate(15deg);

	@media (min-width: ${600}px) {
		margin-top: -90px;
		margin-left: 140px;
		transform: rotate(15deg);
		width: 120px;
	}

	@media (min-width: ${size.DESKTOP}px) {
		margin-top: -70px;
		margin-left: 220px;
		transform: rotate(35deg);
	}

	@media (min-width: ${size.DESKTOP_LARGE}px) {
		margin-top: -90px;
		margin-left: 220px;
		transform: rotate(15deg);
	}
`;
