import styled from 'styled-components';
import { colors, size } from '../../config';

export const Header = styled.div`
	position: fixed;
	margin: 0px;
	padding: 0px;
	top: 0;
	left: 0;
	width: calc(100vw - ${4}px);
	height: 50px;
	z-index: 99;
	color: #c1c1c1;
	background: rgba(50, 60, 61, .55);
	background: linear-gradient(0deg, rgba(50, 60, 61, 0) 0%, rgba(50, 60, 61, 0.7) 90%);
	border-bottom: 1px solid rgba(255, 255, 255, 0.075);
	backdrop-filter: blur(7px);
	transition: background 0.25s ease;

	@media (min-width: ${size.TABLET}px) {
		height: 100px;
		/* width: calc(100vw - ${5}px); */
	}

	&:hover .header-show {
		opacity: 0;
		transform: translateY(0px) scale(0.5, 0.5);
	}

	.header-show {
		font-size: 50px;
		position: absolute;
		left: ${size.PADDING_SMALL}px;
		top: -10px;
		transition: opacity 0.25s ease;
		@media (min-width: ${size.TABLET}px) {
			font-size: 80px;
			left: ${size.PADDING_LARGE}px;
		}
	}

	&:hover:hover .header-nav {
		opacity: 1;
		transform: translateY(0px);
	}

	.header-nav {
		position: absolute;
		padding: 10px;
		top: ${-14}px;
		left: ${4}px;
		cursor: pointer;

		img {
			width: 20px;
		}

		svg {
			fill: ${colors.ORANGE_2};
			stroke: ${colors.ORANGE_2};
			line {
				fill: ${colors.ORANGE_2};
				stroke: ${colors.ORANGE_2};
			}
			width: 12px;
			transition: transform .2s ease, stroke .2s ease;
		}

		.back-ico {
			transform: rotate(90deg);
			margin-top: 24px;
				fill: ${colors.ORANGE_3};
				stroke: ${colors.ORANGE_3};
				line {
					fill: ${colors.ORANGE_3};
					stroke: ${colors.ORANGE_3};
				}
		}

		@media (min-width: ${size.TABLET}px) {
			position: absolute;
			top: ${10}px;
			left: ${28}px;
			svg {
				width: 20px;
			}
		}

		&:hover {
			svg {
				fill: ${colors.ORANGE_1};
				stroke: ${colors.ORANGE_1};
				line {
					fill: ${colors.ORANGE_1};
					stroke: ${colors.ORANGE_1};
				}
			}
			.back-ico {
				transform: translateX(-5px) rotate(90deg);
			}
		}
	}

	.header-logo {
		width: 130px;
		right: 14px;
		top: 19px;
		position: absolute;
		transition: transform .2s ease, opacity .2s ease;
		cursor: pointer;
		img {
			width: 100%;
		}
		@media (min-width: ${size.TABLET}px) {
			width: 200px;
			right: 32px;
			top: 42px;
			opacity: 1;
			transform: translateX(0);
		}
	}

	@media (min-width: ${size.TABLET}px) {
		.header-show {
			opacity: 0;
			transform: translateY(0px) scale(0, 0);
		}
		.header-nav {
			opacity: 1;
			transform: translateY(0px);
		}
	}
`;
