import styled from "styled-components";
import { colors, size } from "../../config";

export const Drawer = styled.div`
	width: calc(280px - ${size.LARGE}px);
	height: 100%;
	padding: ${size.TINY}px;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	background: ${colors.SLATE};
	transform: ${props => (props.open ? "translateX(0)" : "translateX(-360px)")};
	transition: transform .25s ease;
	img {
		width: 160px;
		cursor: pointer;
	}
	.logo {
		position: absolute;
		bottom: ${size.MEDIUM}px;
		padding-left: 6px;
	}
	p {
		font-size: ${11}px;
	}

	@media (min-width: ${size.TABLET}px) {
		padding: ${size.MEDIUM}px;
		padding-bottom: 0;
		.logo {
			bottom: ${size.XLARGE}px;
		}	
	}
`;

export const Overlay = styled.div`
	width: 100%;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 998;
	opacity: ${props => (props.open ? 0.7 : 0)};
	pointer-events: ${props => (props.open ? "auto" : "none")};
	background: ${colors.GRAD_ORANGE};
	// backdrop-filter: blur(15px);
	/* background: ${colors.GREEN}; */
	transition: opacity .3s .05s ease;
`;

export const NavItem = styled.button`
	outline: none;
	width: 100%;
	text-align: left;
	height: 40px;
	border: 0;
	font-family: 'helios';
	font-size: 1.2rem;
	/* background: ${"#fff"}; */
	background: ${colors.SLATE};
	cursor: pointer;
	transition: color .12s ease;
	color: ${colors.ORANGE_1};
	&:hover {
		color: ${colors.ORANGE_3};
	}


`;
