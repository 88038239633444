import styled from 'styled-components';
import { size, colors } from '../../config';

export const Cover = styled.div`
	margin: 0;
	height: 100vh;
	min-height: 1000px;
	z-index: 1;
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	font-weight: 200;
	padding: ${size.PADDING_SMALL}px ${size.PADDING_SMALL}px;
	color: ${colors.ORANGE_2};
	h3 {
		margin: 0;
		padding: 0;
		font-size: 1.6rem;
		line-height: 1.6rem;
		font-family: 'helios';
	}

	@media (min-width: ${size.TABLET}px) {
		padding: ${size.PADDING_SMALL}px ${size.PADDING_LARGE}px;
		h3 {
			font-size: 2.0rem;
			line-height: 2.0rem;
		}
	}
`;
export const Title = styled.h1`
	font-size: 2.4rem;
	line-height: 2.6rem;
	letter-spacing: 0.1rem;
	margin-top: 10vh;
	font-family: 'Inter';
	font-weight: 600;
	padding-bottom: 0;
	margin-bottom: ${size.MEDIUM}px;
	z-index: 2;
	position: relative;
	max-width: 1100px;

	@media (min-width: ${size.MOBILEXL}px) {
		font-size: 3rem;
		line-height: 3.3rem;
		margin-top: 20vh;
	}
	@media (min-width: ${size.DESKTOP_SMALL}px) {
		font-size: 3.55rem;
		line-height: 3.85rem;

		.bottom-row {
			margin-top: ${size.MEDIUM}px;
		}
	}


	@media (min-width: ${size.MOBILEXL}px) {
		font-size: 3rem;
		line-height: 3rem;
	}
	@media (min-width: ${size.DESKTOP_SMALL}px) {
		font-size: 4.45rem;
		line-height: 4.5rem;
	}
	@media (min-width: ${2000}px) {
		font-size: 6rem;
		line-height: 6rem;
	}
	@media (min-width: ${2500}px) {
		font-size: 8rem;
		line-height: 8rem;
	}
`;
export const Info = styled.p`
	font-size: 1.5rem;
	padding-bottom: ${size.MEDIUM}px;
	max-width: 600px;
`;

export const Row = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	@media (min-width: ${size.TABLET}px) {
		width: calc(100vw - 138px);
		flex-direction: row;
		align-items: center;
	}
`;
export const RowLabel = styled.div`
	height: 50px;
	@media (min-width: ${size.DESKTOP_SMALL}px) {
		height: 75px;
		margin: 0;
	}
`;

export const PhoneContainer = styled.div`
	position: relative;
	transform: translate(-40px, 64px) scale(0.8) rotate(-5deg);
	transition: transform .25s ease;
	transform-style: flat;
	pointer-events: none;
	/* margin: 80px 0 200px; */

	@media (min-width: ${420}px) {
		transform: translate(0px, 64px) scale(1) rotate(-5deg);
	}

	@media (min-width: ${size.TABLET}px) {
		transform: translate(-70px, 64px) scale(1) rotate(-5deg);
	}

	@media (min-width: ${size.DESKTOP}px) {
		margin: 0;
		position: absolute;
		right: 0;
		top: 18vh;
		z-index: -1;
		transform: translate(100px, 200px) scale(0.75) rotate(6deg);
	}

	@media (min-width: ${size.DESKTOP_LARGE}px) {
		transform: translate(40px, -80px) scale(0.8) rotate(0deg);
	}

	@media (min-width: ${1600}px) {
		transform: translate(0px, -60px) scale(0.9) rotate(3deg);
	}

	@media (min-width: ${1700}px) {
		transform: translate(-110px, -50px) scale(1) rotate(3deg);
	}
`;
export const Img = styled.div`
	position: absolute;
	right: -40px;
	width: 500px;
	height: 500px;
	padding: 4px;
	z-index: 1;
	overflow: hidden;
	transform-style: flat;
	margin: 0px -240px 0 0;

	img {
		width: 100%;
		height: 100%;
	}

	@media (min-width: ${size.DESKTOP}px) {
		margin: 0;
		position: relative;
		width: 800px;
		height: 800px;
	}
`;
export const ImgBtm = styled.div`
	position: absolute;
	right: -40px;
	width: 500px;
	height: 500px;
	margin: -80px -20px 0 0;
	z-index: -1;
	overflow: hidden;
	transform-style: flat;

	border-radius: 50%;
	overflow: hidden;
	background-color: ${colors.ORANGE_2};
	padding: ${size.SMALL}px;
	transition: transform 0.15s ease-out;

	.imgContainer {
		background-color: transparent;
	}

	img {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		transition: transform 0.15s ease-out 0.025s;
	}

	@media (min-width: ${size.TABLET}px) {
		margin: -80px -130px 0 0;
	}

	@media (min-width: ${size.DESKTOP}px) {
		margin: 210px 90px 0 0;
		width: 800px;
		height: 800px;
	}
	@media (min-width: ${size.DESKTOP_LARGE}px) {
		margin: 400px 100px 0 0px;
	}
	@media (min-width: ${1600}px) {
		margin: 300px -20px 0 0px;
	}
`;
export const Making = styled.div`
	position: absolute;
	z-index: 0;
	top: 900px;
	right: 160px;
	font-size: 28px;
	line-height: 34px;
	transform: translate(-20px, -600px) scale(1) rotate(0deg);
	transition: transform .25s ease;
	display: none;
	color: ${colors.ORANGE_1};

	@media (min-width: ${400}px) {
		/* display: block; */
	}

	@media (min-width: ${size.MOBILEXL}px) {
		/* top: 600px; */
		right: 390px;
		font-size: 28px;
		line-height: 32px;
	}

	@media (min-width: ${size.TABLET}px) {
		display: block;
		transform: translate(-20px, -700px) scale(1) rotate(0deg);
	}

	@media (min-width: ${size.DESKTOP}px) {
		display: block;
		font-size: 48px;
		line-height: 46px;
		position: relative;
		top: auto;
		right: auto;
		transform: translate(-240px, 120px) scale(1) rotate(-10deg);
	}

	@media (min-width: ${size.DESKTOP_LARGE}px) {
		transform: translate(-390px, 180px) scale(1) rotate(0deg);
	}

	svg {
		width: 140px;
		display: block;
		
		.path {
			fill: ${colors.ORANGE_4};
			stroke-dasharray: 160;
			stroke-dashoffset: 0;
			animation: dash 1s linear;
		}
		
		@keyframes dash {
			from {
				stroke-dashoffset: 160;
			}
			to {
				stroke-dashoffset: 0;
			}
		}
	}
`;

export const Arrow = styled.div`
	position: absolute;
	width: 60px;
	height: auto;
	margin-top: -120px;
	margin-left: 30px;
	transform: rotate(15deg);
	display: none;

	@media (min-width: ${600}px) {
		margin-top: -130px;
		margin-left: 200px;
		transform: rotate(15deg);
		width: 120px;
		display: block;
	}

	@media (min-width: ${size.DESKTOP}px) {
		margin-top: -120px;
		margin-left: 220px;
		transform: rotate(5deg);
	
	}

	@media (min-width: ${size.DESKTOP_LARGE}px) {
		margin-top: -120px;
		margin-left: 280px;
		transform: rotate(15deg);
	}
`;
