import styled from "styled-components";
import { colors, size } from "../../config";

export const Ball = styled.div`
	width: 32px;
	height: 32px;
	border-radius: 24px;
	border: 0;
    z-index: 0;
    position: absolute;
	outline: none;
	background: ${colors.ORANGE_1};
	box-shadow: 0px 0px 0px rgba(23, 23, 57, 0);

	transition: transform .15s ease;
    animation: end 2s ease-in-out .2s infinite forwards;

	&:hover {
		/* background: red; */
		/* transform: translateY(-3px); */
		box-shadow: 0px 2px 0px rgba(23, 23, 57, 0.22);
	}




    &.ball1 { animation-delay: 0s; }
    &.ball2 { 
        animation-delay: .45s;
        width: 24px;
	    height: 24px;
     }

    @keyframes end {
        0% { transform: translateY(0%); }
        50% { transform: translateY(40px); }
    }

	@media (min-width: ${size.MOBILEXL}px) {

	}
`;