import { createContext, useContext } from "react";

export const AppModel = {
	navigationOpen: false,
	home: {},
	projects: [],
	mvp: {}
};

export const DispatchContext = createContext();
DispatchContext.displayName = "AppDispatch";

const AppContext = createContext();
AppContext.displayName = "AppState";
export default AppContext;

export const AppState = () => useContext(AppContext);
