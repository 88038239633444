import React, { useEffect } from "react";
import AppContext, { DispatchContext } from "./context";
import ReactGA from "react-ga";
import Router from "./routes";
import { useAppContext } from "./hooks";

export const App = props => {
	const { state, dispatch } = useAppContext();

	useEffect(() => {
		console.log(
			`%c deprogram | v3.0 | thanks for looking`,
			"background: linear-gradient(159deg, #FFB76B 0%, #e57c29 100%); color: #252525; padding: 8px"
		);
		console.log(
			`%c contact Josh : info@deprogram.io`,
			"background: #e57c29; color: #fff; padding: 8px"
		);

		ReactGA.initialize("UA-162761349-1");
	}, []);

	return (
		<DispatchContext.Provider value={dispatch}>
			<AppContext.Provider value={state}>
				<Router />
			</AppContext.Provider>
		</DispatchContext.Provider>
	);
};

export default App;
