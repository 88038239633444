import styled from 'styled-components'
import { colors, size } from '../../config'

export const Cover = styled.div`
  margin: 0;
  height: 110vh;
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  padding: ${size.PADDING_SMALL}px ${size.PADDING_SMALL}px;

  @media (min-width: ${size.TABLET}px) {
    padding: ${size.PADDING_SMALL}px ${size.PADDING_LARGE}px;
  }
`
export const Title = styled.h1`
	font-family: 'Inter';
	font-weight: 600;
	padding-bottom: 0;
	margin-bottom: ${size.MEDIUM}px;
	z-index: 2;
	position: relative;
	max-width: 1100px;
  color: ${colors.ORANGE_2};

	@media (min-width: ${size.MOBILEXL}px) {
		font-size: 3rem;
		line-height: 3.3rem;
	}
	@media (min-width: ${size.DESKTOP_SMALL}px) {
		font-size: 3.55rem;
		line-height: 3.85rem;
	}


	@media (min-width: ${size.MOBILEXL}px) {
		font-size: 3rem;
		line-height: 3rem;
	}
	@media (min-width: ${size.DESKTOP_SMALL}px) {
		font-size: 4.45rem;
		line-height: 4.5rem;
	}
	@media (min-width: ${2000}px) {
		font-size: 6rem;
		line-height: 6rem;
	}
	@media (min-width: ${2500}px) {
		font-size: 8rem;
		line-height: 8rem;
	}
`
export const Info = styled.p`
  font-size: 1.5rem;
  padding-bottom: ${size.MEDIUM}px;
`
export const Phones = styled.div`
  position: absolute;
  width: 60vw;
  right: -10vw;
  bottom: 7vh;
  margin: 190px 0;

  img {
    width: 95%;
  }
`
export const IPhone = styled.div`
  position: relative;
  top: 0;
  z-index: 1;
  transform: scale(0.85) rotate(-20deg) translateX(20px) translateY(55%);
  transition: transform .1s ease;

  .screen {
    background: linear-gradient(
      159deg,
      rgba(206, 76, 255, 1) 0%,
      rgba(24, 14, 246, 1) 100%
    );
  }

  @media (min-width: ${size.DESKTOP}px) {
    transform: scale(0.85) rotate(-20deg) translateX(160px) translateY(5%);
  }
`

export const GPixel = styled.div`
  position: absolute;
  top: 0;
  z-index: 0;
  transform: scale(0.7) rotate(-20deg) translateX(-130px) translateY(400px);
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`
export const RowLabel = styled.div`height: 55px;`
