import styled from "styled-components";
import { colors } from "../../config";

export const Image = styled.img`
	width: 100%;
	height: auto;
`;

export const ImgContainer = styled.div`
    padding: 32px 0;
`;

export const Bar = styled.div`
	width: 22px;
    height: 3px;
    background-color: ${colors.ORANGE_2};
    margin: 9px 0;
    overflow: hidden;

    div {
        width: 100%;
        height: 100%;
        background-color: ${colors.ORANGE_1};
        transform: translateX(-100%); 
        animation: preloadSlide 2s ease-out .2s infinite forwards;
    }

    .bar1 { animation-delay: 0s; }
    .bar2 { animation-delay: .25s; }
    .bar3 { animation-delay: .5s; }

    @keyframes preloadSlide {
        0% { transform: translateX(-22px); }
        50%, 90% { transform: translateX(0); }
    }
`;


