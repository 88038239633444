import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Home, Showcase, Error, Project, MVP } from './screens'
import { Drawer } from "./components"

export const AppRouter = props => {

  const renderHome = () => {
    return (
      <Home
      />
    );
  }

  return (
    <>
    <Router>
      <Drawer />
      <Switch>
        <Route exact path='/' render={renderHome} />
        <Route exact path='/mvp-development/' component={MVP}  />
        <Route exact path='/showcase/' component={Showcase}  />
        <Route exact path='/showcase/:projectId' component={Project} />
        <Route component={Error} />
      </Switch>
    </Router>
    </>
  )
}

export default AppRouter
