import React from "react";
import { Button } from "../../components";
import { Footer, Social, Icon, Row } from "./styles";

const FooterComponent = props => {
	const onEmail = () => {
		window.open(
			"mailto:info@deprogram.io?subject=Let's work together!",
			"_blank"
		);
	};
	return (
		<>
		<Footer className="row">
			<div className="container">
				<Row>
					<Button
						text={"Start the conversation"}
						onPress={onEmail}
						icon="chat"
					/>
					<Social>
						<a
							href="https://www.linkedin.com/in/deprogram/"
							target="_blank"
							rel="noopener noreferrer">
							<Icon>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24">
									<path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
								</svg>
							</Icon>
						</a>
						<a
							href="https://twitter.com/_deprogram_"
							target="_blank"
							rel="noopener noreferrer">
							<Icon style={{paddingTop: 5}}>
							<svg width="20" height="20" viewBox="0 0 300 300.25">
								<path d="M178.57,127.15L290.27,0h-26.46l-97.03,110.38L89.34,0H0l117.13,166.93L0,300.25h26.46l102.4-116.59,81.8,116.59h89.34M36.01,19.54h40.65l187.13,262.13h-40.66"/>
							</svg>
							</Icon>
						</a>
					</Social>
				</Row>
				<p className="text-size-medium">
					<a href="tel:+61478196213">+(61) 478196213</a>
				</p>
				<p className="text-size-medium address">
					PO Box 120
					<br />
					<span role="img" aria-label="tree">
						🌴{" "}
					</span>{" "}
					MULLUMBIMBY{" "}
					<span role="img" aria-label="tree">
						🌴{" "}
					</span>{" "}
					<br />2482, 
					NSW, Australia
				</p>

				<p className="legal">
					© {new Date().getFullYear()} Deprogram Pty Ltd. All Rights Reserved.<br />ABN: 42617766300
				</p>
			</div>
		</Footer>
			<div className="foot-spacer" />		
		</>
	);
};

export default FooterComponent;
