import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useTrail, animated } from "react-spring";
import { useAppState } from "../../hooks"
import logo from "../../assets/images/deprogram-24.svg";
import { SET_NAV } from "../../actions"

import { Drawer, Overlay, NavItem} from "./styles";

const DrawerComponent = props => {
	const menuItems = [
		{
			label: "Case studies",
			link: "showcase"
		},
		{
			label: "MVP development",
			link: "mvp"
		},
	]
	const {state, dispatch} = useAppState()
	const [navItems] = useState(menuItems)

    const history = props.history;

	const onNavClick = (section) => {
		// eslint-disable-next-line default-case
		switch (section) {
			case "home":
				history.push("/");
				break;
			case "showcase":
				history.push("/showcase");
				break;
				case "mvp":
					history.push("/mvp-development");
					break;
		}
		onClose();
	};


	const onClose = () => {
		dispatch({ type: SET_NAV, navigationOpen: false });
	}
	
	const trail = useTrail(navItems.length, {
		from: { opacity: 0.7, transform: "translate3d(-80px,0px,0) scale(1)" },
		to: { opacity: 1, transform: "translate3d(0,0px,0) scale(1)" },
		reset: true
	});
	return <>
		<Drawer open={state.navigationOpen}>
            {trail.map(({ ...rest }, index) => {
				const item = navItems[index]
				return (
					<animated.div
						key={index}
						style={{ ...rest }}>
						<NavItem onClick={() => onNavClick(item.link)}>{item.label}</NavItem>
					</animated.div>
				);
			})}
			<div className='logo'>
				<img src={logo} alt='deprogram' onClick={() => onNavClick("home")} />
				<p>© 2024 Deprogram Pty Ltd. 
				<br/>All Rights Reserved.
					ABN: 42617766300</p>
			</div>
        </Drawer>
		<Overlay open={state.navigationOpen} onClick={onClose} />
    </>;
};

export default withRouter(DrawerComponent);
