import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga";
import {Helmet} from "react-helmet";
import Prismic from "prismic-javascript";
import { SET_MVP_DATA } from "../../actions";
import { useCMS } from "../../hooks";
import { CoverMvp, MVPInfo, Footer, Header } from "../../components";
import { Inner } from "../../assets/styles/root";
import "../../assets/styles/base.css";

const MVPScreen = props => {
	const { dispatch, Client } = useCMS();

	useEffect(() => {
		window.scrollTo(0, 0);
		const fetchData = async () => {
			const response = await Client.query(
				Prismic.Predicates.at("document.type", "mvp")
			);
			if (response) {
				response.results[0] &&
					dispatch({ type: SET_MVP_DATA, data: response.results[0].data });
			}
		};
		fetchData();
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);

	return (
		<div className="App color-bg">
			<Helmet>
				<title>Deprogram - MVP Development</title>
				<link rel="canonical" href="https://www.deprogram.io/mvp-development" />
			</Helmet>
			<Inner>
				<Header />
				<div className="vs-section">
					<CoverMvp />
					<MVPInfo />
					<Footer />
				</div>
			</Inner>
		</div>
	);
};

export default withRouter(MVPScreen);
