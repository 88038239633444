import React, { useEffect } from "react";
import ReactRotatingText from "react-rotating-text";

import { Value, ValueItem } from "./styles";

const TextRotatorComponent = React.memo(props => {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <Value>
      <ValueItem dark={props.dark.toString()}>
        <ReactRotatingText items={props.items} />
      </ValueItem>
    </Value>
  );
});

TextRotatorComponent.defaultProps = {
  items: [ "start-ups", "agencies", "entrepreneurs", "visions" ],
  interval: 750,
  dark: false
};

export default TextRotatorComponent;
