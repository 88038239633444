import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { SET_PROJECTS } from '../../actions';
import {Helmet} from "react-helmet";

import Prismic from 'prismic-javascript';
import ReactGA from 'react-ga';
import { RichText } from 'prismic-reactjs';
import { linkResolver } from '../../utils/tools';
import { useCMS, useAppState } from '../../hooks';
import { Button, Header, Footer, Image, Preloader } from '../../components';
import { Inner } from '../../assets/styles/root';

import {
	Project,
	Cover,
	Description,
	Detail,
	Loader,
	Gallery,
	Tech
} from './styles';

const ProjectComponent = props => {
	const { projectId } = useParams();
	const { Client } = useCMS();
	const { state, dispatch } = useAppState();
	const [ currentProject, setCurrentProject ] = useState(null);
	const { projects } = state;

	useEffect(() => {
		window.scrollTo(0, 0);
		ReactGA.pageview(window.location.pathname + window.location.search);
		return () => {};
	}, []);

	useEffect(
		() => {
			if (!projects.length) fetchProjects();
			else {
				filterProject();
			}
		},
		[ projects ]
	);

	const filterProject = () => {
		const current = projects.filter(proj => proj.uid === projectId);
		// console.log("current project ", current, projectId);
		current[0] && setCurrentProject(current[0].data);
	};

	const fetchProjects = async () => {
		const response = await Client.query(
			Prismic.Predicates.at('document.type', 'p')
		);
		if (response) {
			response.results[0] &&
				dispatch({ type: SET_PROJECTS, data: response.results });
		}
	};

	const onVisitPress = () => {
		window.open(currentProject['project-link'].url, '_blank');
	};

	const projectTitle = currentProject && currentProject['project-name'][0]?.text || 'Project Showcase';

	return (
		<div className='App color-bg'>
			<Helmet>
				<title>Deprogram - {projectTitle}</title>
				<link rel="canonical" href={`https://www.deprogram.io/${projectId}`} />
			</Helmet>
			<Inner>
				<Header backEnabled />
				<div className='vs-section'>
					{currentProject ? (
						<Project>
							<Cover>
								<Image
									src={
										currentProject['project-gallery'][0]['project-gallery-item']
											.url
									}
									alt={currentProject['project-name'].text}
								/>
							</Cover>
							<Gallery>
								{currentProject['project-gallery'].map((item, i) => {
									if (i === 0) return null;
									else
										return (
											<Image
												key={i}
												src={
													currentProject['project-gallery'][i][
														'project-gallery-item'
													].url
												}
												alt={currentProject['project-name'].text}

											/>
										);
								})}
							</Gallery>
							<Detail>
								<h2 className='color-highlight'>
									{currentProject['project-cilent'][0].text}
								</h2>
								<h1>{currentProject['project-name'][0].text}</h1>
								<Description>
									{RichText.render(
										currentProject['project-description'],
										linkResolver
									)}
								</Description>

								{currentProject['project-link'] &&
								currentProject['project-link'].url && (
									<Button text='Visit project' onPress={onVisitPress} />
								)}

								{currentProject['project-tech'].length > 0 && (
									<Tech>
										<h4 className='color-highlight'>
											built with technologies including:
										</h4>
										{currentProject['project-tech'].map((item, i) => {
											const tag =
												item['tech-list-item'][0] &&
												item['tech-list-item'][0].text;
											return (
												<span key={tag}>
													{tag}
													{i < currentProject['project-tech'].length - 1 && (
														<span className='color-highlight'> - </span>
													)}
												</span>
											);
										})}
									</Tech>
								)}
							</Detail>
						</Project>
					) : (
						<Loader>
							<Preloader />
						</Loader>
					)}
					<Footer />
				</div>
			</Inner>
		</div>
	);
};

export default withRouter(ProjectComponent);
