import React from "react";
import { withRouter } from "react-router-dom";

import { Button, LogoCard } from "../../components";

import { Clients, Content, Title } from "./styles";

const ClientComponent = props => {
	const onViewCasestudy = () => {
		props.history.push("/showcase");
	};

	return (
		<Clients>
			<div className="container">
				<Title style={{ transform: `translateY(${props.offset * 0.2}px)` }}>
					trusted by the large & small
				</Title>
				<Content>
					<LogoCard logo={"google"} size={45} />
					<LogoCard logo={"nrl"} size={70} />
					<LogoCard logo={"ustwo"} size={35} />
					<LogoCard logo={"qantas"} size={33} />
					<LogoCard logo={"careof"} size={27} />
					{/* <LogoCard logo={"rex"} size={30} /> */}
					<LogoCard logo={"samewave"} size={80} />
					{/* <LogoCard logo={"samsung"} size={22} /> */}
					<LogoCard logo={"merrier"} size={60} />
					<LogoCard logo={"piiq"} size={40} />
				</Content>
				<Button
					onPress={onViewCasestudy}
					text="view the case studies"
					icon="arrow"
				/>
			</div>
		</Clients>
	);
};

export default withRouter(ClientComponent);
