import { useContext, useReducer } from "react";
import Prismic from "prismic-javascript";
import { AppReducer } from "../reducer";
import AppContext, { DispatchContext, AppModel } from "../context";

export const useAppContext = () => {
	const [ state, dispatch ] = useReducer(AppReducer, AppModel);

	return { state, dispatch };
};

export const useAppState = () => {
	const state = useContext(AppContext);
	const dispatch = useContext(DispatchContext);

	return { state, dispatch };
};

export const useCMS = () => {
	const dispatch = useContext(DispatchContext);
	const apiEndpoint = process.env.REACT_APP_PRISMIC_API;
	const accessToken = process.env.REACT_APP_PRISMIC_KEY;
	const Client = Prismic.client(apiEndpoint, { accessToken });

	return { Client, dispatch };
};
