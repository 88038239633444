import { SET_NAV, SET_HOME_DATA, SET_PROJECTS, SET_MVP_DATA } from "../actions";

export const AppReducer = (state, action) => {
	switch (action.type) {
		case SET_NAV:
			return {
				...state,
				navigationOpen: action.navigationOpen
			};

		case SET_HOME_DATA:
			return {
				...state,
				home: action.data
			};

		case SET_MVP_DATA:
			return {
				...state,
				mvp: action.data
			};

		case SET_PROJECTS:
			return {
				...state,
				projects: action.data
			};
		default:
			return state;
	}
};
