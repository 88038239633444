import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import { RichText } from "prismic-reactjs";
import AppContext from "../../context";
import { Button } from "../../components";
import { linkResolver } from "../../utils/tools";
import { Bio, Title, Info } from "./styles";

const MVPInfoComponent = props => {
	const state = useContext(AppContext);
	return (
		<Bio>
			<div className="container">
				<div className="bio-tech">
					<Title>
						{state.mvp &&
							state.mvp["mvp-title"] &&
							RichText.render(state.mvp["mvp-title"], linkResolver)}
					</Title>
					<Info>
						{state.mvp &&
							state.mvp["mvp_body"] &&
							RichText.render(state.mvp["mvp_body"], linkResolver)}
					</Info>
				</div>
				<div>
					<Button
						onPress={() => props.history.push("./showcase")}
						text="View the case studies"
						icon="arrow"
					/>
				</div>
			</div>
		</Bio>
	);
};

export default withRouter(MVPInfoComponent);
