import styled from "styled-components";
import { size, colors } from "../../config";

export const Project = styled.div`
	width: 100%;
	max-width: 1400px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-top: 60px;
	margin-bottom: ${size.LARGE}px;
	h1 {
		padding: 0 ${size.MEDIUM}px;
		font-size: 2.5rem;
		margin: ${size.SMALL}px 0;
		font-family: 'Inter';
		font-weight: 200;
		color: ${colors.ORANGE_2};
	}
	h2 {
		margin: 0 0 ${size.SMALL}px;
		font-family: 'helios';
	}
	h4 {
		font-family: 'helios';
	}

	button {
		width: calc(100% - 64px);
	}

	@media (min-width: ${size.TABLET}px) {
		padding-top: 100px;
	}
`;

export const Cover = styled.div`
	/* height: calc(90vh - 100px); */
	width: 100%;
	overflow: hidden;
	border-radius: ${size.SMALL}px;
	img {
		width: 100%;
		border-radius: ${size.SMALL}px;
	}
`;
export const Description = styled.div`
	max-width: 800px;
	text-align: left;
	line-height: 1.5em;
	padding: ${0}px ${size.MEDIUM}px ${size.LARGE}px;
	p {
		color: ${colors.GREY_TEXT};
	}
`;

export const Detail = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding-top: ${size.LARGE}px;
	min-height: 800px;
`;

export const Loader = styled.div`
	width: 100%;
	height: 80vh;
	display: flex;
	justify-content: center;
	align-items: center;
	img {
		width: 80px;
	}
`;

export const Gallery = styled.div`
	display: block;
	flex-direction: column;

	img {
		width: 100%;
		margin: ${8}px 0;
		border-radius: ${size.SMALL}px;
	}

	.imgContainer{
			width: 100%;
			background-color: transparent;
	}

	.imgLoader{
		/* background-color: red; */
		display: flex;
		width: 100%;
	}

	@media (min-width: ${size.TABLET}px) {
		display: flex;
		flex-direction: row;

		.imgContainer{
			width: 50%;
			background-color: transparent;

			&:nth-child(odd) {
				margin-right: ${size.SMALL / 2}px;
			}
			&:nth-child(even) {
				margin-left: ${size.SMALL / 2}px;
			}
		}



		/* img {
			width: auto;
			max-width: calc(50% - ${size.SMALL / 2}px);
			margin: ${8}px 0;

			&:nth-child(odd) {
				margin-right: ${size.SMALL / 2}px;
			}
			&:nth-child(even) {
				margin-left: ${size.SMALL / 2}px;
			}
		} */
	}
`;

export const Tech = styled.div`
	/* width: 100%; */
	font-family: 'Inter';
	font-weight: 200;
	font-size: 1.7rem;
	margin: ${size.MEDIUM}px;
	text-align: left;
	color: ${colors.ORANGE_2};
	h4 {
		font-size: 18px;
		font-weight: normal;
	}
	@media (min-width: ${size.TABLET}px) {
		width: 85%;
		margin: ${size.MEDIUM}px auto;
	}
`;
