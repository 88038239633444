import styled from "styled-components";
import { size } from "../../config";
export const Border = styled.div`
	/* width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center; */
	/* background: linear-gradient(
    159deg,
    rgba(206, 76, 255, 1) 0%,
    rgba(24, 14, 246, 1) 100%
  ); */
`;
export const Inner = styled.div`
	/* background: rgba(255,255,255, 0.9) url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAIklEQVQoU2NkIBIwEqmOgT4KzzAwMJigO4k+VmMNCOpbDQDrmQELnsvwhQAAAABJRU5ErkJggg==)
    repeat; */
	/* background: #e7e7e7;
	/* box-shadow: 0px 3px 7px rgba(23, 23, 57, 0.3); */
	/* box-shadow: -8px -4px 8px 0px #ffffff, 8px 4px 12px 0px #d1d9e6,
		4px 4px 4px 0px #d1d9e6, -4px -4px 4px 0px #ffffff; */

	/* width: calc(100% - ${size.SMALL}px);
	height: calc(100% - ${size.SMALL}px);

	@media (min-width: ${size.TABLET}px) {
		width: calc(100% - ${size.MEDIUM}px);
		height: calc(100% - ${size.MEDIUM}px);
	} */
`;
