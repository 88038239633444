import styled from 'styled-components';
// import { animated } from 'react-spring'
import { size, colors } from '../../config';

export const Footer = styled.div`
	margin: 0;
	width: calc(100% - ${42}px);
	text-align: left;
	padding: ${size.PADDING_SMALL}px ${size.PADDING_SMALL}px 0;
	background: rgba(255, 255, 255, .07);
	font-family: 'Inter';
	font-weight: 200;
	transform: translate(0, -16px);
	border-radius: ${size.MEDIUM}px;

	.container {
		width: calc(100% - ${0}px);
		max-width: none;
	}

	.address {
		color: ${colors.GREY_TEXT};
	}

	a:link {
		color: ${colors.ORANGE_3};
		text-decoration: none;
		border-bottom: none;
	}

	.legal {
		margin: ${size.MEDIUM}px 0 ${size.SMALL}px;
		font-size: 13px;
		line-height: 18px;
		color: ${colors.GREY_TEXT};
		@media (min-width: ${size.MOBILEXL}px) {
			margin-top: 50px;
			margin-bottom: 50px;
		}
	}

	@media (min-width: ${600}px) {
		width: calc(100% - ${138}px);
		margin: 10vh 0 0px;
		padding: ${size.PADDING_LARGE}px ${size.PADDING_LARGE}px 0;
		border-radius: ${size.LARGE}px;

		.container {
			width: calc(100% - ${0}px);
		}
	}
`;

export const Row = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	@media (min-width: ${600}px) {
		flex-direction: row;
		align-items: center;
	}
`;
export const Social = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
`;
export const Icon = styled.div`
	margin-left: ${size.SMALL}px;
	margin-top: ${size.MEDIUM}px;
	@media (min-width: ${size.MOBILEXL}px) {
		margin-top: 0px;
	}
	svg {
		fill: ${colors.GREY_TEXT};
	}
	&:hover {
		svg {
			fill: ${colors.ORANGE_1};
		}
	}
`;
