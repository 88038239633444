import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useTrail, animated } from 'react-spring';
import {Helmet} from "react-helmet";

import ReactGA from 'react-ga';
import lozad from 'lozad';
import Prismic from 'prismic-javascript';
import { SET_PROJECTS } from '../../actions';
import { useCMS, useAppState } from '../../hooks';
import { Footer, Header, Preloader, ProjectThumb } from '../../components';
import { Inner } from '../../assets/styles/root';
import { Projects, Loader } from './styles';

// import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
// import 'swiper/swiper.min.css';
// import 'swiper/modules/effect-cards.min.css';

// import required modules
// import { EffectCards } from 'swiper/modules';


const Showcase = props => {
	const { Client } = useCMS();
	const { state, dispatch } = useAppState();
	const { projects } = state;

	useEffect(() => {
		window.scrollTo(0, 0);
		// Setup lazy loading
		const observer = lozad();
		observer.observe();
		// Get the projects
		fetchProjects();
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);

	const fetchProjects = async () => {
		const response = await Client.query(
			Prismic.Predicates.at('document.type', 'p')
		);
		if (response) {
			response.results[0] &&
				dispatch({ type: SET_PROJECTS, data: response.results });
		}
	};

	const onProjectPress = id => {
		props.history.push('/showcase/' + id);
	};

	const projectsByDate =
		projects &&
		projects
			.slice()
			.sort(
				(a, b) =>
					new Date(b.data['project-date']) - new Date(a.data['project-date'])
			);

	const trail = useTrail(projectsByDate ? projectsByDate.length : 0, {
		from: { opacity: 0, transform: 'translate3d(0,60px,0) scale(1)' },
		to: { opacity: 1, transform: 'translate3d(0,0px,0) scale(1)' }
	});

	// console.log("Projects, ", projects);

	return (
		<div className='App color-bg'>
			<Helmet>
				<title>Deprogram - Project case studies</title>
				<link rel="canonical" href="https://www.deprogram.io/showcase" />
			</Helmet>
			<Inner>
				<Header />
				<div className='vs-section'>
					<Projects>

						{projects && projects.length ? (
							trail.map(({ ...rest }, index) => {
								const item = projectsByDate[index];
								// const prjDate = new Date(item.data["project-date"])
								// if (prjDate.getFullYear() < 2021) return null
								return (
									<animated.div
										key={item.uid}
										className='trails-text'
										style={{ ...rest }}>
										<ProjectThumb
											key={item.uid}
											item={item.data}
											onClick={onProjectPress.bind(null, item.uid)}
										/>
									</animated.div>
								); 
							})
						) : (
							<Loader>
								<Preloader />
							</Loader>
						)}
					</Projects>
					<Footer />
				</div>
			</Inner>
		</div>
	);
};

export default withRouter(Showcase);
