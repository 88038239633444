import styled from 'styled-components';
import { size, colors } from '../../config';

export const Bio = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: ${0}px ${size.PADDING_SMALL}px;
	font-size: 1.5rem;
	margin: 0px 0  ${size.LARGE}px;
	p {
		color: ${colors.GREY_TEXT};
	}
	h4 {
		font-size: 20px;
		letter-spacing: 2px;
		font-family: 'helios';
		/* color: ${colors.GREY_DARK}; */
	}
	a:link,
	a:active,
	a:visited {
		background: ${colors.GRAD_ORANGE};
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;

		text-decoration: none;
		padding-bottom: 0px;
		border-bottom: 1px solid ${colors.ORANGE_1};
	}
	a:hover {
		background: ${colors.ORANGE_2};
		border-bottom: 1px dashed #c3c3c3;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	.bio-tech {
		font-family: 'Inter';
		font-weight: 200;
		font-size: 1.5rem;
		color: ${colors.ORANGE_1};
	}
	.bio-tech span {
		will-change: color;
		transition: color 1s ease-out;
	}
	.bio-tech span:hover {
		color: ${colors.ORANGE_2};
		transition: color 0.15s ease-out;
	}

	button {
		margin: ${size.MEDIUM}px 0;
		font-size: 1.2rem;
		span {
			font-size: 1.2rem;
		}
	}

	.card-container {
		display: flex;
		flex-direction: column;
	}
	@media (min-width: ${size.TABLET}px) {
		// margin: 320px 0 0;
		// padding: ${size.PADDING_SMALL}px ${size.PADDING_LARGE}px;

		// .bio-tech {
		// 	font-size: 2.5rem;
		// }
	}

	@media (min-width: ${size.DESKTOP}px) {
		// margin: 320px 0 0;
		margin: 0px 0 0;
		padding: ${size.PADDING_SMALL}px ${size.PADDING_LARGE}px;

		.bio-tech {
			font-size: 2.5rem;
		}

		.card-container {
			flex-direction: row;
		 }
	}

	.avatar {
		border-radius: 50%;
		overflow: hidden;
		width: 140px;
		height: 144px;
		margin-right: ${size.MEDIUM}px;
		background-color: ${colors.ORANGE_2}ae;
		backdrop-filter: blur(4px);
		padding: ${size.TINY}px;
		transition: transform 0.15s ease-out;
		z-index: 1;
		position: relative;
		img {
			width: 100%;
			border-radius: 50%;
			transition: transform 0.15s ease-out 0.025s;
		}
	}

	.avatar:hover {

			transform: scale(1.05);
			img {
				transform: scale(1.025);
			}
		
	}
`;

export const BioRow = styled.div`
	display: flex;
	flex-direction: column;

	.bioDesc {
		margin-top: -0;
	}

	.desk-stuff { 
		margin: 50px -8px 0 0 ;
		display: none;
		padding: ${size.TINY / 2}px;
		transition: transform 0.15s ease-out;

		border-radius: 50%;
		/* overflow: hidden; */
		width: 180px;
		height: 180px;
		
		background-color: ${colors.ORANGE_2}ae;
		padding: ${size.TINY}px;
		transition: transform 0.15s ease-out;
		
		img { 
			max-width: 180px;
			border-radius: 180px;
			border-radius: 50%;
			transition: transform 0.15s ease-out 0.025s;
		}
	}

	.desk-stuff:hover {
		transform: scale(1.05);
		img {
			transform: scale(1.025);
		}
	}


	&:hover {
		@media (min-width: ${size.TABLET}px) {
			.head{ 
				transform: scale(1.2) translate(20px ,-10px) rotate(10deg)
			}
		}
	}

	@media (min-width: ${size.MOBILEXL}px) {

	}
	@media (min-width: ${size.TABLET}px) {
		flex-direction: row;
		.bioDesc {
			margin-top: -${size.SMALL}px;
		}
		.desk-stuff { 
			display: block 
		}
	}

	@media (min-width: ${size.DESKTOP_LARGE}px) {
		.bioDesc {
			max-width: 65%
		}
	}
`;

export const Tech = styled.div``;
