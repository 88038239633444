import React, { useMemo } from "react";
import { withRouter } from "react-router-dom";

import { Button, TextRotator } from "../../components";
import { Cover, Title, Row, RowLabel } from "./styles";

const CoverComponent = React.memo(props => {
  const workingWithList = useMemo(
    () => [
      "error",
      "bad thing",
      "mistake",
      "broken link",
      "dead end",
      "whoopsy",
      "trump",
      "mess up"
    ],
    []
  );

  const onHomePress = () => {
    props.history.push("/");
  };

  return (
    <Cover
      className="vs-section"
      style={{
        transform: `translateY(${props.offset * 0.34}px)`
      }}>
      <div className="container">
        <Title
          style={{
            transform: `translateY(-${props.offset * 0.14}px`
          }}>
          Oh no 😕 <br />
          <Row>
            <RowLabel style={{marginBottom: 32}}>theres been a</RowLabel>
          </Row>
          <Row >
            <div><TextRotator items={workingWithList} red /></div>
          </Row>
        </Title>
          <div style={{marginTop: 32}}>
            <Button onPress={onHomePress} text="Go Home" icon="arrow" />
          </div>
      </div>
    </Cover>
  );
});

export default withRouter(CoverComponent);
