export const size = {
	MAX_WIDTH: 1200,
	PADDING_LARGE: 70,
	PADDING_MEDIUM: 35,
	PADDING_SMALL: 20,
	PADDING_TINY: 10,
	DESKTOP: 1200,
	DESKTOP_SMALL: 1024,
	DESKTOP_LARGE: 1400,
	TABLET: 720,
	MOBILEXL: 600,
	MOBILE: 360,
	XLARGE: 62,
	LARGE: 48,
	MEDIUM: 32,
	SMALL: 16,
	TINY: 8
};

export const colors = {
	GRAD_PURPLE_BLUE:
		"linear-gradient(159deg, rgba(97, 153, 217, 1) 0%, rgba(163, 104, 176, 1) 100%)",
	GRAD_ORANGE: "linear-gradient(159deg, #FFB76B 0%, #e57c29 100%)",
	PURPLE_1: "rgba(97, 153, 217, 1)",
	PURPLE_2: "rgba(163, 104, 176, 1)",
	GREEN: "#00ff00",
	GREY_BASE: "#c1c1c1",
	GREY_DARK: "#252525",
	GREY_TEXT: '#aca8a8',
	ORANGE_1: "#FFB76B",
	ORANGE_2: "#75604f",
	ORANGE_3: "#e57c29",
	ORANGE_4: "#FF9900",
	DARK: "#252726",
	SLATE: "#373b3c"
};
