import React from "react";
import { Button } from "./styles.js";
import { RenderIcon } from "../../utils";

const ButtonComponent = props => {
	const onPress = () => {
		props.onPress();
	};

	return (
		<Button onClick={onPress} icon="question">
			<span>{props.text}</span>
			{props.icon && RenderIcon(props.icon)}
		</Button>
	);
};

ButtonComponent.defaultProps = {
	text: "",
	onPress: () => {},
	icon: null
};

export default ButtonComponent;
