import React from "react";
import { Image } from "../../components";
import { ProjThumb, ImgContainer, Titles } from "./styles";

const ProjectThumb = props => {
	// console.log("props", props.item);

	return (
		<ProjThumb
			onClick={props.onClick.bind(null, props.item.id)}
			style={{
				transform: `translateY(-${props.offset + props.index}px`
			}}>
			<ImgContainer className="border-highlight">
				<Image
					src={props.item["project-thumb"].url}
					alt={props.item["project-thumb"].alt || "project"}
				/>
			</ImgContainer>
			<Titles>
				<h3>{props.item["project-cilent"][0].text}</h3>
				<h2>{props.item["project-name"][0].text}</h2>
			</Titles>
		</ProjThumb>
	);
};

export default ProjectThumb;
