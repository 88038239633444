import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import Prismic from "prismic-javascript";
import {Helmet} from "react-helmet";
import ReactGA from "react-ga";
import { SET_HOME_DATA } from "../../actions";
import { useCMS } from "../../hooks";
import { Bio, Cover, Footer, Header, Service } from "../../components";

import { Inner } from "../../assets/styles/root";
import "../../assets/styles/base.css";

const Home = props => {
	const { dispatch, Client } = useCMS();

	useEffect(() => {
		window.scrollTo(0, 0);

		const fetchData = async () => {
			const response = await Client.query(
				Prismic.Predicates.at("document.type", "home_bio")
			);
			if (response) {
				// console.log("Data__ ", response);
				response.results[0] &&
					dispatch({ type: SET_HOME_DATA, data: response.results[0].data });
			}
		};
		fetchData();
		ReactGA.initialize("UA-162761349-1");
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);

	return (
		<div className="App color-bg">
			<Helmet>
				<title>Deprogram - Software Development: Apps, Mobile, MVPs</title>
				<link rel="canonical" href="https://www.deprogram.io" />
			</Helmet>
			<Inner>
				<Header />
				<div className="vs-section">
					<Cover offset={0} />
					<Bio offset={0} />
					<Service offset={0} />
					<Footer />
				</div>
			</Inner>
		</div>
	);
};

export default withRouter(Home);
